import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { Box } from '../components/box';
import { ContactSection } from '../components/contact';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { ServiceJumpTable } from '../components/servicejumptable';
import { makeSectionId } from '../components/jumpboxlink';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import Seo from '../components/seo';

function SecurityRating({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t('eval_title', 'Security Posture Evaluierung'),
            desc: t(
                'eval_desc',
                'Wie sieht das aktuelle Security Niveau aus und wo ist noch Luft nach oben?'
            ),
        },
        {
            title: t(
                'intern_comparison_title',
                'Interner Security Posture Vergleich'
            ),
            desc: t(
                'intern_comparison_desc',
                'Organisationsweit einheitliches Security Niveau etablieren'
            ),
        },
        {
            title: t(
                'extern_comparison_title',
                'Externer Security Posture Vergleich'
            ),
            desc: t(
                'extern_comparison_desc',
                'Die Konkurrenz schnell hinter sich lassen'
            ),
        },
    ];

    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title={t('title', 'Cyber Security Rating')}
                    text={t(
                        'subtitle',
                        'Security messbar und vergleichbar - die perfekte Basis für schnellere und fundierte Entscheidungen.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/rating.png"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                    buttonUrl="https://app.condignum.com/rating"
                    buttonText={t('start', 'Rating starten')}
                    buttonIcon={<ShieldCheckIcon className="w-4 h-4 mr-1" />}
                />
            }
        >
            <Seo title={t('title', 'Cyber Security Rating')} />
            <PageSection size="big">
                <PageSubtitle className="max-w-3xl">
                    <Trans i18nKey="intro_1">
                        Tausende Events - ein einfaches Rating. Analog zu einem
                        Rating der Kreditwürdigkeit bewertet das condignum Cyber
                        Security Rating Ihre aktuelle Security Posture auf einer
                        einfachen Skala von A bis E.
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="intro_2">
                        Wie jedes Qualitätsmerkmal einer Organisation kostet
                        auch Security Geld. Und dieses will mit Bedacht an den
                        richtigen Stellen investiert werden, wozu eine
                        informierte und solide Entscheidungsbasis notwendig ist.
                        Diese ist jedoch in den meisten Fällen nicht vorhanden
                        bzw. nicht belastbar. Security messbar und vergleichbar
                        zu machen ist daher eine der größten Herausforderungen.
                        Aber eine lösbare…
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" />

            <PageSection size="big" id={makeSectionId(overview[0].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="eval_title">
                            Security Posture Evaluierung
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="eval_desc">
                            Wie sieht das aktuelle Security Niveau aus und wo
                            ist noch Luft nach oben?
                        </Trans>
                    </PageSubtitle>
                </header>
                <Col3>
                    <Box as="li">
                        <Punchline>
                            <Trans i18nKey="eval_col1_punchline">
                                Schnell und unkompliziert
                            </Trans>
                        </Punchline>
                        <PageText>
                            <Trans i18nKey="eval_col1_desc">
                                Einfache und vergleichbare Evaluierung des
                                initialen Security Niveaus Ihrer Organisation.
                            </Trans>
                        </PageText>
                    </Box>
                    <Box as="li">
                        <Punchline>
                            <Trans i18nKey="eval_col2_punchline">
                                Leicht verständlich
                            </Trans>
                        </Punchline>
                        <PageText>
                            <Trans i18nKey="eval_col2_desc">
                                Das aktuelle Security Niveau der Organisation
                                wird schnell und treffsicher evaluiert und
                                aussagekräftig visualisiert. Dabei wird
                                aufgezeigt, wie groß der jeweilige
                                Handlungsbedarf in den einzelnen Teilbereichen
                                ist und wo das größte Optimierungspotenzial
                                steckt.
                            </Trans>
                        </PageText>
                    </Box>
                    <Box as="li">
                        <Punchline>
                            <Trans i18nKey="eval_col3_punchline">
                                Sofort verfügbar
                            </Trans>
                        </Punchline>
                        <Ul>
                            <li>
                                <Trans i18nKey="eval_col3_desc1">
                                    Ergebnis-Report im PDF Format
                                </Trans>
                            </li>
                            <li>
                                <Trans i18nKey="eval_col3_desc2">
                                    Einstufung des Security Niveaus auf einer
                                    einfachen Skala von A-E
                                </Trans>
                            </li>
                        </Ul>
                    </Box>
                </Col3>
            </PageSection>
            <PageSection size="big" id={makeSectionId(overview[1].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="intern_comparison_title">
                            Interner Security Posture Vergleich
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="intern_comparison_desc">
                            Organisationsweit einheitliches Security Niveau
                            etablieren
                        </Trans>
                    </PageSubtitle>
                </header>
                <Col2>
                    <Box as="li">
                        <Trans i18nKey="intern_comparison_col1">
                            Das aktuelle Security Niveau aller
                            Organisationseinheiten und Assets wird schnell und
                            einfach festgestellt. Es kann bequem verglichen
                            werden um organisationsintern voneinander zu lernen,
                            die Sicherheit schrittweise zu steigern und ein
                            gemeinsames Zielniveau zu erreichen.
                        </Trans>
                    </Box>
                    <Box as="li">
                        <Ul>
                            <li>
                                <Trans i18nKey="intern_comparison_col2_1">
                                    Ergebnis-Report im PDF Format
                                </Trans>
                            </li>
                            <li>
                                <Trans i18nKey="intern_comparison_col2_2">
                                    Detailergebnisse in der Plattform
                                </Trans>
                            </li>
                            <li>
                                <Trans i18nKey="intern_comparison_col2_3">
                                    Direkter Vergleich zwischen
                                    Organisationseinheiten und Assets
                                </Trans>
                            </li>
                        </Ul>
                    </Box>
                </Col2>
            </PageSection>
            <PageSection size="big" id={makeSectionId(overview[2].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="extern_comparison_title">
                            Externer Security Posture Vergleich
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="extern_comparison_desc">
                            Die Konkurrenz schnell hinter sich lassen
                        </Trans>
                    </PageSubtitle>
                </header>
                <Col2>
                    <Box as="li">
                        <Trans i18nKey="extern_comparison_col1">
                            Das eigene Security Niveau kann mit vergleichbaren
                            Organisationen sowie Branchen und Ländern verglichen
                            werden. Dadurch können Optimierungspotenziale
                            entdeckt und Security Maßnahmen zielgerichtet
                            gesetzt werden.
                        </Trans>
                    </Box>
                    <Box as="li">
                        <Punchline>
                            <Trans i18nKey="extern_comparison_col2">
                                Security Rating Vergleich mit:
                            </Trans>
                        </Punchline>
                        <Ul>
                            <li>
                                <Trans i18nKey="extern_comparison_col2_1">
                                    Referenzorganisationen
                                </Trans>
                            </li>
                            <li>
                                <Trans i18nKey="extern_comparison_col2_2">
                                    Branchendurchschnitten
                                </Trans>
                            </li>
                            <li>
                                <Trans i18nKey="extern_comparison_col2_3">
                                    Geografischen Durchschnitten
                                </Trans>
                            </li>
                        </Ul>
                    </Box>
                </Col2>
            </PageSection>
            <ContactSection isDark={false} />
        </Layout>
    );
}

function Punchline({ children }) {
    return (
        <PageText type="highlighted" className="mb-2">
            {children}
        </PageText>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["security-rating"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecurityRating;
